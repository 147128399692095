<template>
  <div class="contMain_HeaderShopping w-full grid grid-cols-8 px-2 xl:px-6 xl:grid-cols-12 gap-0 font-bold" v-if="Ruta.toUpperCase() != '/AVISOLEGAL'">
    <div class="col-span-3 xl:col-span-3 grid grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7 items-center align-middle z-50" :class="modoCompra == false ? ' xl:col-span-2' : ' xl:col-span-3'">
      <div class="cont_MostrarMenu scale-75 xl:scale-100  flex align-middle items-center justify-center">
        <i class="fa-solid fa-bars cursor-pointer" @click="setMostrarMenuShopping()"></i>
      </div>
      <div  class="flex align-middle scale-75 xl:scale-100  items-center justify-center">
        <router-link :to="'/'">
          <i class="fa-solid fa-house block scale-125 cursor-pointer"></i>
        </router-link>
      </div>
      <div class="cont_carrito flex scale-75 xl:scale-100  align-middle items-center justify-center z-50" v-if="modoCompra">
        <router-link  to="/DetallePedido">
          <i class="fa-solid fa-cart-shopping cursor-pointer">
            <router-link to="/DetallePedido" class="cont_cantidadesProductos scale-75 " v-if="CantidadProductosCarrito.length > 0">{{ CantidadProductosCarrito.length }}</router-link>
          </i>
        </router-link>
      </div>
      <div class="w-full h-full flex justify-center items-center align-middle col-span-1 xl:col-span-2">
        <router-link class="relative block mx-auto w-20 h-12 scale-75 xl:scale-100 text-xs ml-2 py-2 px-1 rounded-2xl bg-[#0f233c]" to="/DigitacionCodigos" v-if="modoCompra">{{ "DIGITAR PEDIDO" }}</router-link>
      </div>
    </div>
    <router-link :to="'/'" class="cont_LogoJuanaBonita scale-75 xl:scale-100  flex cursor-pointer" :class="modoCompra == false ? ' col-span-2 xl:col-span-6' : ' xl:hidden col-span-2 xl:col-span-8'">
      <img class="w-52 hidden xl:block" src="/img/JB/JB_Logo_Blanco.png" alt="">
      <img class="w-14 block xl:hidden" src="/img/JB/logo_blanco.png" alt="">
    </router-link>
    <div class="hidden xl:flex col-span-3 xl:col-span-6 align-middle items-center justify-center z-40 text-sm" v-if="modoCompra">
      <div class="w-full md:block font-semibold " @mouseover="marcacionesMouse('entra')" @mouseleave="marcacionesMouse('sale')">
        <router-link class="genero mx-[0.3%] 2xl:mx-[0.5%] " :class="Genero.replaceAll(' ','_')" v-for="(Genero,index) in Generos" :to="'/Productos'" @mouseover="setCategorias(Genero,index,true)" @click="setCategorias(Genero,index,false)" ><i class="fa-solid fa-chevron-right flecha mr-0 2xl:mr-[0.2rem]"></i>{{ Genero }}</router-link>
        
        <div class="absolute flex flex-row w-full justify-center items-start bg-[#f93a5a] left-0 pt-4" :class=" Categorias ? 'border-b' : 'border-b-0'">
          <router-link class="categoria cursor-pointer " :class="Categoria.replaceAll(' ','_')" v-for="(Categoria,index1) in Categorias" :to="'/Productos'"><span @click="setCategoriaSeleccionada(Categoria)">{{ Categoria }}</span>
            <div class="w-full font-normal grid" :class="getGrupos(Categoria).length < 4 ? (Categorias.length > 2 ? 'grid-cols-1' : 'grid-cols-' + getGrupos(Categoria).length) : 'grid-cols-3'">
              <div class="separadorGrupos absolute w-[1px] h-[80%] bg-current float-right right-[-1rem]" v-if="index1 < (Categorias.length - 1)"></div>
              <router-link class="grupo py-1 mx-4 flex flex-col justify-center text-left text-xs" :class="getGrupos(Categoria).length >= 3 ? ' w-[7rem] items-start': 'items-center'" v-for="(Grupo,index2) in getGrupos(Categoria)" @mouseover="setGrupoSeleccionado(Categoria, index1, Grupo,index2,true)" @click="setGrupoSeleccionado(Categoria, index1, Grupo,index2,false)" :to="'/Productos'">{{ Grupo }}</router-link>
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="cont_btn_BuscarPagina hidden xl:flex align-middle items-center justify-center z-50" v-if="modoCompra">
      <span class="absolute table top-3 font-semibold text-xs">BUSCAR PÁGINA:</span>
      <div class="relative flex flex-row justify-center items-center align-middle text-white w-24 h-12 mx-auto rounded-lg overflow-hidden pt-2 pl-2 pb-2 mt-4">
        <input class="text-[#f93a5a] h-8 w-full text-center rounded-xl col-span-2 font-semibold" type="number" v-model="PaginaDigitada" @keypress.enter="BuscarPagina()">
        <div class="flex justify-center align-middle items-center h-full w-16 cursor-pointer" @click="BuscarPagina()">
          <i class="fa-solid fa-magnifying-glass scale-125"></i>
        </div>
      </div>
    </div>
    <div class="hidden xl:block col-span-1" v-if="!modoCompra"></div>
    <div class="hidden xl:grid grid-cols-2 z-50 col-span-2">
      <div class="cont_cerrarSesion col-span-2 flex align-middle items-center justify-center font-semibold">
        <div class=" w-[40px] h-[40px] mr-2 rounded-full overflow-hidden">
          <img class="w-full h-full" src="/img/JB/blankProfile.webp" alt="">
        </div>
        <div class="flex-col w-2/3">
          <span class="NombreCliente text-[0.7rem] text-left leading-3 block mb-1">{{ infoCliente.PrimerNombre + " " + infoCliente.SegundoNombre + " " + infoCliente.PrimerApellido + " " + infoCliente.SegundoApellido }}</span>
          <div class="flex flex-row justify-between">
            <router-link class="text-[0.7rem] hover:underline cursor-pointer" to="/DatosPersonales">Mi Cuenta</router-link>
            <span class="text-[0.7rem] hover:underline cursor-pointer" @click="cerrarSesion()">Cerrar Sesión</span>
          </div>
        </div>
      </div>
    </div>
    <div class="cont_MenuCategoriasMovil scale-75 xl:scale-100  flex xl:hidden flex-row justify-center items-center align-middle" v-if="modoCompra" @click="setMostrarMenuProductos()">
        <i class="fa-solid fa-shirt cursor-pointer mr-2"></i>
      </div>
    <div class="flex xl:hidden flex-row justify-center items-center align-middle cursor-pointer border-b-0 hover:border-b-8 duration-300 ease-in-out" :class="modoCompra ? 'col-span-2' : 'col-span-3'" @click="cerrarSesion()">
      <!--<i class="fa-solid fa-power-off"></i>-->
      <span class="font-semibold text-sm xl:text-base">Cerrar <br> Sesión</span>
    </div>
  </div>
  <div class="bannerAnuncos absolute top-[80px] right-0 bg-[#b81430] text-white h-[2.1rem] w-full z-10" v-if="Ruta.toUpperCase() != '/AVISOLEGAL'">
    <div class="animAnuncios relative text-lg w-max block font-semibold mt-[0.2rem]">{{ Anuncios }}</div>
  </div>
  <div class="bannerAnuncos absolute top-[113.6px] bg-[#f93a5a] text-white h-[2rem] sm:h-[1.5rem] w-[80vw] md:w-[70vw] xl:w-[40vw] mx-[10vw] md:mx-[15vw] xl:mx-[30vw] z-10 rounded-[0rem_0rem_1rem_1rem]" v-if="Ruta.toUpperCase() != '/AVISOLEGAL'">
    <div class="TipoFacturacion text-xs sm:text-sm text-center top-0">
      <div class=" inline-block"><span>TIPO FACTURACIÓN:</span><span class="ml-1 font-semibold"> {{ infoCliente.IdTipoFacturacion == 1 ? "CRÉDITO" : infoCliente.IdTipoFacturacion == 2 ? "PAGO ANTICIPADO" : infoCliente.IdTipoFacturacion == 3 ? "EMPLEADO" : "N/A" }}</span></div>
      <div v-if="estadoPedido > 0" class="sm:ml-2 block sm:inline-block"><span class="sm:border-l-white sm:border-l sm:pl-2">ESTADO PEDIDO:</span><span class="ml-1 font-semibold">{{ estadoPedido == 2 ? "PASE" : estadoPedido == 3 ? "LIBRE" : estadoPedido == 4 ? "PICKING" : estadoPedido == 5 ? "FACTURADO" : estadoPedido == 7 ? "ELIMINADO" : estadoPedido == 8 ? "ABIERTO" : estadoPedido == 9 ? "TRANSPORTES" : estadoPedido == 10 ? "SINIESTRADO" : "N/A" }}</span></div>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default{
  name: "HeaderShopping",
  setup(){
    const store = useStore()

    const Anuncios = computed(() => store.state.anuncio)

    const infoCliente = computed(() => store.state.dataUsuario.infoCliente)

    const estadoPedido  = computed(() => store.state.estadoPedido)
    //console.log(estadoPedido.value)

    const leaderList = computed(() => store.state.leaderList.filter((el) => {return el.Pagina >= 2 && el.Pagina <= 156}))
    //console.log(leaderList.value)

    const modoCompra = computed(() => store.state.modoCompra)
    
    store.state.Generos = [...new Set(leaderList.value.map(el => el.Genero))]
    const Generos = computed(() => store.state.Generos)
    const Categorias = computed(() => store.state.Categorias)
    const Grupos = computed(() => store.state.Grupos)
    
    //console.log(Generos.value)
    
    const generoSeleccionadoActual = computed(() => store.state.generoSeleccionadoHover)
    const generoSeleccionadoActualClick = computed(() => store.state.generoSeleccionado)
    const categoriaSeleccionadaActual = computed(() => store.state.categoriaSeleccionadaHover)
    const grupoSeleccionadoActual = computed(() => store.state.grupoSeleccionadoHover)

    const marcaGeneros = ref(false)

    const route = useRoute()
    const Ruta = computed(() => route.path)

    const PaginaDigitada = ref(undefined)
    const BuscarPagina = () => {
      if(PaginaDigitada.value < 2 || PaginaDigitada.value > 156){
        Swal.fire({
          icon: 'error',
          title: 'PÁGINA FUERA DE RANGO!',
          text: 'EL CATÁLOGO VA DESDE LA PÁGINA 2 HASTA LA PÁGINA 156',
        })
      }else{
        store.state.paginaSeleccionadaProductos = {Pagina: PaginaDigitada.value, index: 0}
        store.state.generoSeleccionadoProductos = undefined
        store.state.categoriaSeleccionadaProductos = undefined
        store.state.grupoSeleccionadoProductos = undefined
        document.querySelectorAll(".genero").forEach((element,index) => {
          element.style = ""
        });
        document.querySelectorAll(".genero i").forEach((element,index) => {
          element.style = ""
        });
        router.push("/Productos")
      }
    }

    const marcacionesMouse = (tipo) => {
      ////console.log(tipo,item)
      if(tipo == 'entra'){
        marcaGeneros.value = true
      }else{
        if(generoSeleccionadoActual.value != undefined){
          //document.querySelectorAll(".genero i")[store.state.generoSeleccionado.index].style = ""
          //document.querySelectorAll(".genero")[store.state.generoSeleccionado.index].style = ""
          //document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_')).forEach((element,index) => {
          //  document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_'))[index].style = ""
          //  document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_') + " i")[index].style = ""
          //});
          marcaGeneros.value = false
          store.state.Categorias = undefined
          //store.state.categoriaSeleccionada = undefined
          //store.state.generoSeleccionado = undefined
          store.state.Grupos = undefined
          //store.state.grupoSeleccionado = undefined
          document.querySelectorAll(".genero i").forEach((element,index) => {
            element.style = ""
          });
          document.querySelectorAll(".genero").forEach((element,index) => {
            element.style = ""
          });
        }
      }
    }

    const setCategorias = (Genero,index, hover) => {
      if(hover){
        store.state.generoSeleccionadoHover = {Genero: Genero, index: index}
      
        store.state.Categorias = [...new Set(leaderList.value.filter((el) => {return el.Genero == Genero}).map(el => el.Categoria))]
        store.state.categoriaSeleccionadaHover = undefined
        
        store.state.Grupos = undefined
        store.state.grupoSeleccionadoHover = undefined

        document.querySelectorAll(".genero." + Genero.replaceAll(' ','_')).forEach((element,index) => {
          document.querySelectorAll(".genero." + Genero.replaceAll(' ','_') + " i")[index].style = "rotate: 90deg;"
          document.querySelectorAll(".genero." + Genero.replaceAll(' ','_'))[index].style = "border-bottom: 2px solid"
        });
      }else{
        store.state.paginaSeleccionadaProductos = undefined
        PaginaDigitada.value = undefined

        store.state.generoSeleccionado = {Genero: Genero, index: index}
      
        store.state.Categorias = [...new Set(leaderList.value.filter((el) => {return el.Genero == Genero}).map(el => el.Categoria))]
        store.state.categoriaSeleccionada = undefined
        
        store.state.Grupos = undefined
        store.state.grupoSeleccionado = undefined

        store.state.generoSeleccionadoProductos = store.state.generoSeleccionado
        store.state.categoriaSeleccionadaProductos = store.state.categoriaSeleccionada
        store.state.grupoSeleccionadoProductos = store.state.grupoSeleccionado

        //console.log({
        //  generoSeleccionado: store.state.generoSeleccionado,
        //  categoriaSeleccionada: store.state.categoriaSeleccionada,
        //  grupoSeleccionado: store.state.grupoSeleccionado,
        //})
      }
    }
    const getGrupos = (Categoria) => {
      if(generoSeleccionadoActual.value != undefined){
        var GruposTemp = [...new Set(leaderList.value.filter((el) => {return el.Genero == generoSeleccionadoActual.value.Genero && el.Categoria == Categoria}).sort((p1, p2) => (p1.Grupo.length > p2.Grupo.length) ? 1 : (p1.Grupo.length < p2.Grupo.length) ? -1 : 0).map(el => el.Grupo))]
        return GruposTemp
      }else{
        store.state.Categorias = undefined
        return undefined
      }
    }
    const setGrupoSeleccionado = (Categoria,index1,Grupo,index2, hover) =>{
      if(hover){
        store.state.categoriaSeleccionadaHover = {Categoria: Categoria, index: index1}
        store.state.grupoSeleccionadoHover = {Grupo: Grupo, index: index2}
      }else{
        store.state.paginaSeleccionadaProductos = undefined
        PaginaDigitada.value = undefined

        store.state.generoSeleccionado = store.state.generoSeleccionadoHover
        store.state.categoriaSeleccionada = {Categoria: Categoria, index: index1}
        store.state.grupoSeleccionado = {Grupo: Grupo, index: index2}

        store.state.generoSeleccionadoProductos = store.state.generoSeleccionado
        store.state.categoriaSeleccionadaProductos = store.state.categoriaSeleccionada
        store.state.grupoSeleccionadoProductos = store.state.grupoSeleccionado

        document.querySelectorAll(".genero." + store.state.generoSeleccionado.Genero.replaceAll(' ','_')).forEach((element,index) => {
          document.querySelectorAll(".genero." + store.state.generoSeleccionado.Genero.replaceAll(' ','_'))[index].style = "border-bottom: 2px solid"
        });
        //console.log({
        //  generoSeleccionado: store.state.generoSeleccionado,
        //  categoriaSeleccionada: store.state.categoriaSeleccionada,
        //  grupoSeleccionado: store.state.grupoSeleccionado,
        //})
      }
    }
    watch(generoSeleccionadoActual, (ValNuevo, ValViejo) => {
      if(ValNuevo != undefined && ValViejo != undefined){
        if(ValViejo.Genero != ValNuevo.Genero){
          document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_')).forEach((element,index) => {
            document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_') + " i")[index].style = ""
            document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_'))[index].style = ""
          });
        }
      }
    })
    watch(generoSeleccionadoActualClick, (ValNuevo, ValViejo) => {
      if(ValNuevo != undefined && ValViejo != undefined){
        if(ValViejo.Genero != ValNuevo.Genero){
          document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_')).forEach((element,index) => {
            document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_'))[index].style = ""
          });
        }
      }
    })

    const setMostrarMenuShopping = () => {
      store.state.mostrarMenuShopping = !store.state.mostrarMenuShopping
      store.state.mostrarMenuProductos = false
    }

    const setMostrarMenuProductos = () => {
      store.state.mostrarMenuProductos = !store.state.mostrarMenuProductos
      store.state.mostrarMenuShopping = false
    }

    const CantidadProductosCarrito = computed(() => store.state.productosCarrito.filter((el) => {return el.Eliminado == false}))

    const setCategoriaSeleccionada = (Categoria) => {
      document.querySelectorAll(".genero").forEach((element,index) => {
        document.querySelectorAll(".genero")[index].style = ""
      });
      store.state.paginaSeleccionadaProductos = undefined
      PaginaDigitada.value = undefined
      
      store.state.generoSeleccionadoProductos = store.state.generoSeleccionadoHover
      store.state.categoriaSeleccionadaProductos = {Categoria: Categoria, index: 0}
      store.state.grupoSeleccionadoProductos = undefined
      document.querySelectorAll(".genero." + store.state.generoSeleccionadoProductos.Genero.replaceAll(' ','_')).forEach((element,index) => {
        document.querySelectorAll(".genero." + store.state.generoSeleccionadoProductos.Genero.replaceAll(' ','_'))[index].style = "border-bottom: 2px solid"
      });
    }

    const cerrarSesion = () => {
      store.state.Cargando = true
      Cookies.remove("UsuarioJB")
      store.state.Targets = undefined
      store.state.Generos = undefined
      store.state.Categorias = undefined
      store.state.Grupos = undefined
      store.state.generoSeleccionadoHover = undefined
      store.state.categoriaSeleccionadaHover = undefined
      store.state.grupoSeleccionadoHover = undefined
      store.state.generoSeleccionadoProductos = undefined
      store.state.categoriaSeleccionadaProductos = undefined
      store.state.grupoSeleccionadoProductos = undefined
      store.state.generoSeleccionado = undefined
      store.state.categoriaSeleccionada = undefined
      store.state.grupoSeleccionado = undefined
      store.state.paginaSeleccionada = undefined
      store.state.productosCarrito = []
      store.state.sesionActiva = false
      store.state.esCliente = false
      store.state.modoCompra = false
      store.state.tienePedido = false
      store.state.Cargando = false
      store.state.estadoPedido = 0
      store.state.anuncio = ""
      router.push("/")
    }

    return{
      Anuncios,
      infoCliente,
      estadoPedido,
      modoCompra,
      PaginaDigitada,
      Ruta,
      
      Generos,
      Categorias,
      Grupos,
      CantidadProductosCarrito,

      BuscarPagina,
      setMostrarMenuShopping,
      setMostrarMenuProductos,

      setCategoriaSeleccionada,

      marcacionesMouse,
      setCategorias,
      getGrupos,
      setGrupoSeleccionado,
      setMostrarMenuShopping,
      cerrarSesion
    }
  }
}
</script>

<style>
.contMain_HeaderShopping {
  position: relative;
  background: #f93a5a;
  color: #fff;
}

.cont_carrito i,
.btn_BuscarPagina i,
.cont_cerrarSesion i,
.cont_MenuCategoriasMovil i {
  scale: 1.3;
}

.cont_MostrarMenu i {
  scale: 1.5;
}

.cont_cantidadesProductos {
  color: #fff;
  scale: 0.7;
  position: absolute;
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;
  top: 5px;
  left: 5px;
  background: #0f233c;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  cursor: pointer;
}

.cont_LogoJuanaBonita img {
  margin: auto;
}

.cont_TargetsShopping {
  position: absolute;
  top: 5rem;
  height: auto;
  background: #f93a5a;
  font-weight: 500;
  color: #fff;
  border: #000 solid;
  border-left: 0px;
  border-right: 0px;
  border-bottom: #00000014 solid 1px;
  border-top: #00000014 solid 1px;
}
.cont_GenerosShopping {
  position: relative;
  display: block;
  margin-top: 0rem;
  height: 0rem;
  background: #f93a5a;
  overflow: hidden;
  font-weight: 500;
  color: #fff;
  transition: .3s ease-in-out;
  border-top: #00000014 solid 0px;
}
.cont_CategoriasShopping {
  position: relative;
  display: block;
  height: auto;
  background: #f93a5a;
  overflow: hidden;
  font-weight: 500;
  color: #fff;
  transition: .3s ease-in-out;
  border-top: #00000014 solid 0px;
}
.cont_GruposShopping {
  position: relative;
  display: flex;
  height: auto;
  background: #f93a5a;
  overflow: hidden;
  color: #fff;
  transition: .3s ease-in-out;
  /* border-top: #00000014 solid 1px; */
  align-items: flex-start;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  font-size: 0.8rem;
  text-align: left;
}
.categoria {
  position: relative;
  display: inline-flex;
  margin: 0.3rem 2% 1rem;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
/* .target, .genero, .categoria, */
.categoria span {
  border-bottom: solid 1px currentColor;
  margin-bottom: 0.4rem;
}
.genero{
  position: relative;
  cursor: pointer;
}
.grupo{
  position: relative;
  cursor: pointer;
  transition: .3s ease-in-out;
}
.grupo:hover{
  text-decoration: underline;
}
.flecha{
  transform: scale(0.8);
  color: #fff;
  rotate: 0deg;
  transition: .3s ease-in-out;
}/*
.target:hover .flecha{
  rotate: 90deg;
  color: #f93a5a;
}*/
@keyframes despazamiento {
  0% { 
    margin-left: 100vw;
  }
  100% { 
    margin-left: -3000px;
  }
}
.animAnuncios {
  animation: despazamiento 150s linear infinite;
}

</style>