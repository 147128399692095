import { createRouter, createWebHistory } from 'vue-router'
import Inicio from '../views/Inicio.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Inicio',
    },
    component: Inicio
  },

  //Clientes
  {
    path: '/AvisoLegal',
    name: 'AvisoLegal',
    meta: {
      title: 'Aviso Legal',
    },
    component: function () {
      return import('../views/AvisoLegal.vue')
    }
  },
  {
    path: '/Seleccion',
    name: 'SeleccionProductos',
    meta: {
      title: 'Seleccionar Productos',
    },
    component: function () {
      return import('../views/Seleccion.vue')
    }
  },
  {
    path: '/DigitacionCodigos',
    name: 'DigitacionCodigos',
    meta: {
      title: 'Digitacion de codigos',
    },
    component: function () {
      return import('../views/DigitacionCodigos.vue')
    }
  },
  {
    path: '/DetallePedido',
    name: 'DetallePedido',
    meta: {
      title: 'Detalles del Pedido',
    },
    component: function () {
      return import('../views/DetallePedido.vue')
    }
  },
  {
    path: '/Productos',
    name: 'Productos',
    meta: {
      title: 'Productos',
    },
    component: function () {
      return import('../views/Productos.vue')
    }
  },
  {
    path: '/Facturas',
    name: 'Facturas',
    meta: {
      title: 'Mis Facturas y Cambios o Devoluciones',
    },
    component: function () {
      return import('../views/Facturas.vue')
    }
  },
  {
    path: '/DatosPersonales',
    name: 'DatosPersonales',
    meta: {
      title: 'Mi Cuenta y Datos Personales',
    },
    component: function () {
      return import('../views/DatosPersonales.vue')
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  document.title = to.meta?.title ?? 'Pedidos Juana Bonita'
})

export default router
