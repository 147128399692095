<template>
  <div class="cont_ModalCargando z-50" v-if="Cargando">
    <ModalCargando />
  </div>
  <div v-if="Login && Ruta.toUpperCase() != '/AVISOLEGAL'">
    <Login />
  </div>
  <div class="cont_App_main" v-else>
    <a href="https://api.whatsapp.com/send?phone=573174051977&text=Hola!%20Tengo%20una%20inquietud" target="_blank" class=" fixed flex justify-center items-center align-middle w-[4rem] h-[4rem] rounded-full bg-[#2dd069] text-white left-8 bottom-8 cursor-pointer z-40 shadow-[#00000030] shadow-[0rem_0rem_0.5rem_0rem] " v-if="esCliente && Ruta.toUpperCase() != '/AVISOLEGAL'">
      <i class="fa-brands fa-whatsapp scale-[2.5]"></i>
    </a>
    <div class="fixed flex justify-center items-center align-middle w-[100vw] h-[100vh] z-[39]" v-show="modoCompra && mostrarPopUpClientes">
      <div class="absolute  w-[100vw] h-[100vh] bg-[#000000cc] z-40" @click="cerrarPopUp()"></div>
      <PopUpClientes class="z-50" />
    </div>
    <div class="fixed block right-0 p-1 text-xs sm:p-2 sm:text-base bg-[#f93a5a] rounded-[1rem_0rem_0rem_1rem] top-[40vh] font-semibold text-white cursor-pointer z-[38]" v-show="modoCompra" @click="setMostrarPopUpClientes(true)" >
      <span class="textoBtnPopUp">{{ "OFERTA ÚLTIMO MINUTO" }}</span>
    </div>
    <div class="cont-menu shadow-[0rem_0rem_0.5rem_0rem_#00000080] duration-300 ease-in-out z-40 w-2/3 lg:w-1/2 xl:w-1/5 fixed " :class="mostrarMenuShopping ? ' left-0 ' : ' -left-full '" v-if="esCliente">
      <MenuShopping />
    </div>
    <div class="cont-menuProductos shadow-[0rem_0rem_0.5rem_0rem_#00000080] duration-300 ease-in-out z-40 w-2/3 lg:w-1/2 xl:w-1/5 fixed " :class="mostrarMenuProductos ? ' right-0 ' : ' -right-full '" v-if="esCliente">
      <MenuProductos />
    </div>
    <div class="App_main z-30 w-full">
        <div class="cont-nav-shopping " v-if="esCliente && Ruta.toUpperCase() != '/AVISOLEGAL'">
          <HeaderShopping />
        </div>
        <div class="cont-contenido p-2 sm:p-4 md:p-6 relative block mx-auto" :class="esCliente ? ' w-full xl:w-[90vw] 2xl:w-[80vw] pb-[7rem] md:pb-6' + (Ruta.toUpperCase() == '/AVISOLEGAL' ? ' mt-0 ' : ' mt-[9rem] ') : 'w-full xl:w-10/12 2xl:w-8/12' + (Ruta.toUpperCase() == '/AVISOLEGAL' ? ' mt-0 ' : ' mt-[9rem] ')">
          <router-view/>
        </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from "@vue/runtime-core";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import { watch } from "vue";

import ModalCargando from '@/components/Main/ModalCargando.vue'
import HeaderShopping from '@/components/Main/HeaderShopping.vue'
import PopUpClientes from '@/components/Main/PopUpClientes.vue'
import MenuProductos from '@/components/Main/MenuProductos.vue'
import MenuShopping from '@/components/Main/MenuShopping.vue'
import Login from '@/components/Main/Login.vue'

export default {
  name: 'app',
  components: {
    ModalCargando,
    HeaderShopping,
    PopUpClientes,
    MenuProductos,
    MenuShopping,
    Login
  },
  setup(){
    const store = useStore()

    const Login = computed(() => !store.state.sesionActiva)

    const modoCompra = computed(() => store.state.modoCompra)
    const mostrarPopUpClientes = computed(() => store.state.mostrarPopUpClientes)

    const esCliente = computed(() => store.state.esCliente)

    const mostrarMenuShopping = computed(() => store.state.mostrarMenuShopping)
    const mostrarMenuProductos = computed(() => store.state.mostrarMenuProductos)

    const Cargando = computed(() => store.state.Cargando)

    const route = useRoute()

    const Ruta = computed(() => route.path)

    const setMostrarPopUpClientes = (val) => {
      store.state.mostrarPopUpClientes = val
    }

    //console.log(Ruta.value)

    const productosCarrito = computed(() => store.state.productosCarrito.filter((el) => {return el.Eliminado == false}))

    const UnidadesCarrito = computed(() => productosCarrito.value.reduce((acumulador, objeto)=>{
      return acumulador + objeto.Cantidad
    }, 0))

    const UnidadesPopUp = computed(() => productosCarrito.value.filter((el) => {return el.Pagina == 1}).reduce((acumulador, objeto)=>{
      return acumulador + objeto.Cantidad
    }, 0))

    const cerrarPopUp = () => {
      store.state.mostrarPopUpClientes = false
    }

    return{
      Login,
      modoCompra,
      mostrarPopUpClientes,
      esCliente,
      Cargando,
      mostrarMenuShopping,
      mostrarMenuProductos,
      Ruta,
      setMostrarPopUpClientes,
      cerrarPopUp
    }
  }
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
*{
  -webkit-tap-highlight-color: #f93a5a;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
button:focus,
input:focus,
textarea:focus,
select:focus{
  outline: none;
}
::-moz-selection {
  color: #fff;
  background: #f93a5a;
}

::selection {
  color: #fff;
  background: #f93a5a;
}
/*
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  transition: 0.5s ease-in-out;
  scroll-behavior: smooth;
}
*::-webkit-scrollbar-thumb {
  background: #f93a5a;
  border-radius: 10px;
  cursor: pointer;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*/
.cont-nav {
  display: flex;
  justify-content: center;
}
.cont_ModalCargando.z-50 {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #000000cc;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}
.cont-contenido {
  background: #ffffff;
  font-size: 0.75rem;
  color: #707070;
  border-radius: 1rem;
  /*border: solid 0.1rem #b9b9b9; */
  z-index: 1;
}
/*Clientes*/
.cont_App_main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-end;
}

.cont-menu {
  background: #0f233c;
  color: #fff;
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}
.cont-menuProductos {
  background: #f93a5a;
  color: #fff;
  padding: 1rem;
  height: 100vh;
  overflow-y: auto;
}
.App_main {
  position: relative;
  right: 0;
}
.cont-nav-shopping {
  position: fixed;
  display: flex;
  width: 100%;
  height: 5rem;
  z-index: 40;
}
.textoBtnPopUp{
  writing-mode: vertical-lr;
  text-orientation: mixed;
  rotate: 180deg;
}
</style>
