<template>
  <div class="cont_MostrarMenuShopping">
    <i class="fa-solid fa-xmark cursor-pointer" @click="setMostrarMenuProductos()"></i>
  </div>
  <div class="cont_btn_BuscarPagina flex align-middle items-center justify-center z-50">
    <span class="absolute table top-3 font-semibold text-xs">BUSCAR PÁGINA:</span>
    <div class="relative flex flex-row justify-center items-center align-middle text-white w-24 h-12 mx-auto rounded-lg overflow-hidden pt-2 pl-2 pb-2 mt-4">
      <input class="text-[#f93a5a] h-8 w-full text-center rounded-xl col-span-2 font-semibold" type="number" v-model="PaginaDigitada" @keypress.enter="BuscarPagina()">
      <div class="flex justify-center align-middle items-center h-full w-16 cursor-pointer" @click="BuscarPagina()">
        <i class="fa-solid fa-magnifying-glass scale-125"></i>
      </div>
    </div>
  </div>
  <div class="flex flex-col col-span-3 xl:col-span-7 align-middle items-start justify-start z-40">
    <div class="flex flex-col align-start items-start justify-start w-full font-semibold">
      <div class="genero mx-[0.5%] text-left" :class="Genero.replaceAll(' ','_')" v-for="(Genero,index) in Generos" :to="'/Productos'" ><div @click="setCategorias(Genero,index,false)" class="block p-2"><i class="fa-solid fa-chevron-right flecha mr-[0.2rem]"></i>{{ Genero }}</div>
        <div class="relative flex flex-col w-full justify-center items-center align-middle bg-[#f93a5a] left-0" v-if="generoSeleccionadoActualClick != undefined && Genero == generoSeleccionadoActualClick.Genero">
          <router-link class="categoria cursor-pointer" :class="Categoria.replaceAll(' ','_')" v-for="(Categoria,index1) in Categorias" :to="'/Productos'"><span @click="setCategoriaSeleccionada(Categoria)">{{ Categoria }}</span>
            <div class="w-full font-normal grid grid-cols-3">
              <router-link class="grupo py-1 mx-4 my-2 flex flex-col justify-center text-left text-xs" v-for="(Grupo,index2) in getGrupos(Categoria)"  @click="setGrupoSeleccionado(Categoria, index1, Grupo,index2,false)" :to="'/Productos'">{{ Grupo }}</router-link>
            </div>
          </router-link>
        </div>
      </div>
      <!--<router-link class="genero mx-[0.5%] text-left block p-2" to="Productos/DigitacionCodigos"><i class="fa-solid fa-chevron-right flecha mr-[0.2rem]"></i>{{ "DIGITACION" }}</router-link>-->
    </div>
  </div>
</template>

<script>
import router from '@/router';
import { computed, ref } from "@vue/runtime-core";
import { watch } from "vue";
import { useStore } from 'vuex';
export default{
  name: "MenuProductos",
  setup(){
    const store = useStore()
    const infoCliente = computed(() => store.state.dataUsuario.infoCliente)
    const CampLL = computed(() => store.state.dataUsuario.infoCliente.CampañaIngresoMicrocolsa)
    
    const setMostrarMenuProductos = () => {
      store.state.mostrarMenuProductos = !store.state.mostrarMenuProductos
    }

    const PaginaDigitada = ref(undefined)
    const BuscarPagina = () => {
      if(PaginaDigitada.value < 2 || PaginaDigitada.value > 156){
        Swal.fire({
          icon: 'error',
          title: 'PÁGINA FUERA DE RANGO!',
          text: 'EL CATÁLOGO VA DESDE LA PÁGINA 2 HASTA LA PÁGINA 156',
        })
      }else{
        store.state.paginaSeleccionadaProductos = {Pagina: PaginaDigitada.value, index: 0}
        store.state.generoSeleccionadoProductos = undefined
        store.state.categoriaSeleccionadaProductos = undefined
        store.state.grupoSeleccionadoProductos = undefined
        document.querySelectorAll(".genero").forEach((element,index) => {
          element.style = ""
        });
        document.querySelectorAll(".genero i").forEach((element,index) => {
          element.style = ""
        });
        setMostrarMenuProductos()
        router.push("/Productos")
      }
    }
    
    const marcaGeneros = ref(false)

    const leaderList = computed(() => store.state.leaderList.filter((el) => {return el.Pagina >= 2 && el.Pagina <= 156}))
    let leaderListFiltrado = leaderList.value
    //console.log(leaderListFiltrado)

    watch(leaderList, (N,V) => {
      //console.log(N)
      //console.log(V)
      leaderListFiltrado = leaderList.value.filter((el) => {return el.Pagina != 1})
      store.state.Generos = [...new Set(leaderListFiltrado.map(el => el.Genero))]
    })

    //store.state.Generos = [...new Set(leaderListFiltrado.map(el => el.Genero))]
    const Generos = computed(() => store.state.Generos)
    const Categorias = computed(() => store.state.Categorias)
    const Grupos = computed(() => store.state.Grupos)
    
    //console.log(Generos.value)
    
    const generoSeleccionadoActual = computed(() => store.state.generoSeleccionadoHover)
    const generoSeleccionadoActualClick = computed(() => store.state.generoSeleccionado)
    const categoriaSeleccionadaActual = computed(() => store.state.categoriaSeleccionadaHover)
    const grupoSeleccionadoActual = computed(() => store.state.grupoSeleccionadoHover)

    const marcacionesMouse = (tipo) => {
      ////console.log(tipo,item)
      if(tipo == 'entra'){
        marcaGeneros.value = true
      }else{
        if(generoSeleccionadoActual.value != undefined){
          //document.querySelectorAll(".genero i")[store.state.generoSeleccionado.index].style = ""
          //document.querySelectorAll(".genero")[store.state.generoSeleccionado.index].style = ""
          //document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_')).forEach((element,index) => {
          //  document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_'))[index].style = ""
          //  document.querySelectorAll(".genero." + generoSeleccionadoActual.value.Genero.replaceAll(' ','_') + " i")[index].style = ""
          //});
          marcaGeneros.value = false
          store.state.Categorias = undefined
          //store.state.categoriaSeleccionada = undefined
          //store.state.generoSeleccionado = undefined
          store.state.Grupos = undefined
          //store.state.grupoSeleccionado = undefined
          document.querySelectorAll(".genero i").forEach((element,index) => {
            element.style = ""
          });
        }
      }
    }

    const setCategorias = (Genero,index, hover) => {
      store.state.generoSeleccionadoHover = {Genero: Genero, index: index}
    
      store.state.Categorias = [...new Set(leaderList.value.filter((el) => {return el.Genero == Genero}).map(el => el.Categoria))]
      store.state.categoriaSeleccionadaHover = undefined
      
      store.state.Grupos = undefined
      store.state.grupoSeleccionadoHover = undefined

      document.querySelectorAll(".genero." + Genero.replaceAll(' ','_')).forEach((element,index) => {
        document.querySelectorAll(".genero." + Genero.replaceAll(' ','_') + " i")[index].style = "rotate: 90deg;"
      });
      store.state.paginaSeleccionadaProductos = undefined
      PaginaDigitada.value = undefined

      store.state.generoSeleccionado = {Genero: Genero, index: index}
    
      store.state.Categorias = [...new Set(leaderList.value.filter((el) => {return el.Genero == Genero}).map(el => el.Categoria))]
      store.state.categoriaSeleccionada = undefined
      
      store.state.Grupos = undefined
      store.state.grupoSeleccionado = undefined

      store.state.generoSeleccionadoProductos = store.state.generoSeleccionado
      store.state.categoriaSeleccionadaProductos = store.state.categoriaSeleccionada
      store.state.grupoSeleccionadoProductos = store.state.grupoSeleccionado

      router.push("/Productos")

      //console.log({
      //  generoSeleccionado: store.state.generoSeleccionado,
      //  categoriaSeleccionada: store.state.categoriaSeleccionada,
      //  grupoSeleccionado: store.state.grupoSeleccionado,
      //})
    }
    const getGrupos = (Categoria) => {
      if(generoSeleccionadoActual.value != undefined){
        var GruposTemp = [...new Set(leaderList.value.filter((el) => {return el.Genero == generoSeleccionadoActual.value.Genero && el.Categoria == Categoria}).sort((p1, p2) => (p1.Grupo.length > p2.Grupo.length) ? 1 : (p1.Grupo.length < p2.Grupo.length) ? -1 : 0).map(el => el.Grupo))]
        return GruposTemp
      }else{
        store.state.Categorias = undefined
        return undefined
      }
    }
    const setGrupoSeleccionado = (Categoria,index1,Grupo,index2, hover) =>{
      if(hover){
        store.state.categoriaSeleccionadaHover = {Categoria: Categoria, index: index1}
        store.state.grupoSeleccionadoHover = {Grupo: Grupo, index: index2}
      }else{
        store.state.paginaSeleccionadaProductos = undefined
        PaginaDigitada.value = undefined

        store.state.generoSeleccionado = store.state.generoSeleccionadoHover
        store.state.categoriaSeleccionada = {Categoria: Categoria, index: index1}
        store.state.grupoSeleccionado = {Grupo: Grupo, index: index2}

        store.state.generoSeleccionadoProductos = store.state.generoSeleccionado
        store.state.categoriaSeleccionadaProductos = store.state.categoriaSeleccionada
        store.state.grupoSeleccionadoProductos = store.state.grupoSeleccionado

        setMostrarMenuProductos()

        //console.log({
        //  generoSeleccionado: store.state.generoSeleccionado,
        //  categoriaSeleccionada: store.state.categoriaSeleccionada,
        //  grupoSeleccionado: store.state.grupoSeleccionado,
        //})
      }
    }
    const setCategoriaSeleccionada = (Categoria) => {
      document.querySelectorAll(".genero").forEach((element,index) => {
        document.querySelectorAll(".genero")[index].style = ""
      });
      store.state.paginaSeleccionadaProductos = undefined
      PaginaDigitada.value = undefined

      setMostrarMenuProductos()
      
      store.state.generoSeleccionadoProductos = store.state.generoSeleccionadoHover
      store.state.categoriaSeleccionadaProductos = {Categoria: Categoria, index: 0}
      store.state.grupoSeleccionadoProductos = undefined
    }
    watch(generoSeleccionadoActual, (ValNuevo, ValViejo) => {
      if(ValNuevo != undefined && ValViejo != undefined){
        if(ValViejo.Genero != ValNuevo.Genero){
          document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_')).forEach((element,index) => {
            document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_') + " i")[index].style = ""
          });
        }
      }
    })
    watch(generoSeleccionadoActualClick, (ValNuevo, ValViejo) => {
      if(ValNuevo != undefined && ValViejo != undefined){
        if(ValViejo.Genero != ValNuevo.Genero){
          document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_')).forEach((element,index) => {
            document.querySelectorAll(".genero." + ValViejo.Genero.replaceAll(' ','_'))[index].style = ""
          });
          //console.log(ValNuevo)
          //console.log(ValNuevo.Genero)
        }
      }
    })
    ////console.log(store.state.dataUsuario.infoCliente[0])
    return{
      infoCliente,
      CampLL,
      setMostrarMenuProductos,

      infoCliente,
      PaginaDigitada,
      
      Generos,
      Categorias,
      Grupos,

      generoSeleccionadoActualClick,

      BuscarPagina,
      setMostrarMenuProductos,

      setCategoriaSeleccionada,

      marcacionesMouse,
      setCategorias,
      getGrupos,
      setGrupoSeleccionado,
    }
  }
}
</script>

<style scoped>
.cont_menuShopping_main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  transition: .3s ease-in-out;
}

.seccion {
  padding: 0.4rem 0rem;
  border-bottom: 0.1rem solid #fff;
  width: 100%;
}

.cont_Logo_menu {
  display: block;
  margin: auto;
  width: 80%;
}

.cont_imgPerfil_menu {
  border-radius: 100%;
  overflow: hidden;
  width: 40%;
  margin: 0.5rem auto;
}

.cont_datosCliente_menu span {
  display: block;
}

.TipoCliente {
  font-weight: 700;
}

.NombreCliente {
  font-weight: 600;
}

.TituloGZ {
  display: block;
  font-weight: 700;
}

.NombreGZ {
  display: block;
}

.cont_detallesGZ_menu ul {
  width: 85%;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 0.5rem;
}

.cont_detallesGZ_menu ul li {
  margin: 0.2rem 0rem;
}

.list_opcionesMenu {
  width: 80%;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
}
.list_opcionesMenu li {
  margin: 0.3rem 0rem;
}
.list_opcionesMenu li:hover span {
  text-decoration: underline;
}

.op_menu {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.cont_MostrarMenuShopping {
  position: absolute;
  top: 2%;
  right: 9%;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.cont_MostrarMenuShopping i {
  scale: 1.5;
}
.cont_RRSS {
  display: flex;
  color: #fff;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
  width: 100%;
  padding: 1rem 2rem;
  height: 1rem;
}
.cont_RRSS a {
  width: 1rem;
  display: block;
  cursor: pointer;
  margin: 0px 0.5rem;
}
.cont_RRSS a svg {
  width: 100%;
}
</style>